import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { Anchors } from "../../const/anchors";
import { DataContext } from "../../context/DataContext";
import { Link } from "../../models/schema";
import ContactForm from "../utils/ContactForm";
import ContentWrapper from "../utils/ContentWrapper";
import RenderContent from "../utils/RenderContent";
import { FooterLinks } from "./FooterLinks";
import { Sparring } from "./Sparring";

export const Footer = () => {
  const { content } = useContext(DataContext);
  const contactUs = content?.contactUs;

  const contactsTitle = content?.contactsTitle;
  const contacts = content?.contactsCollection?.items;

  const servicesTitle = content?.servicesTitle;
  const services = content?.servicesCollection?.items;

  const legalTitle = content?.legalTitle;
  const legal = content?.legalCollection?.items;

  return (
    <footer id={Anchors.CONTACT_US}>
      <Divider />
      <ContentWrapper breakpoint="xl">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            "& > div": {
              mt: { xs: 5, lg: 0 },
              mx: { xs: 0, lg: 3 },
            },
            mb: 5,
          }}
        >
          <Box
            sx={{
              width: { xs: 1, lg: "50%" },
            }}
          >
            <RenderContent content={contactUs?.title?.json} />
            <RenderContent
              content={contactUs?.description?.json}
              sx={{ mb: 5, textAlign: "center" }}
            />
            <ContactForm />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              "& > *": {
                mt: { xs: 5, lg: 0 },
                mx: { xs: 0, lg: 2 },
              },
              width: { xs: 1, lg: "50%" },
            }}
          >
            <Box
              sx={{
                width: { xs: 1, lg: "33%" },
              }}
            >
              <Typography variant="h6" mb={5}>
                {contactsTitle}
              </Typography>

              <FooterLinks links={contacts as Link[]} />
            </Box>
            <Box
              sx={{
                width: { xs: 1, lg: "33%" },
              }}
            >
              <Typography variant="h6" mb={5}>
                {servicesTitle}
              </Typography>

              <FooterLinks links={services as Link[]} />
            </Box>
            <Box
              sx={{
                width: { xs: 1, lg: "33%" },
              }}
            >
              <Typography variant="h6" mb={5}>
                {legalTitle}
              </Typography>

              <FooterLinks links={legal as Link[]} />
            </Box>
          </Box>
        </Box>

        <Sparring />
      </ContentWrapper>
    </footer>
  );
};
