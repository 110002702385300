import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useState } from "react";
import { Card } from "../../models/schema";
import RenderContent from "../utils/RenderContent";
export const ExpandableCard = ({
  items,
  lightColor,
  darkColor,
  multiple = true,
}: {
  items: Card[];
  lightColor?: string;
  darkColor?: string;
  multiple?: boolean;
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<string | false>(
    btoa(items[0]?.title as string)
  );

  return (
    <>
      {items?.map((item, i) => {
        const encoded = window.btoa(
          window.unescape(encodeURIComponent(item.title as string))
        );

        return (
          <Accordion
            key={i}
            sx={{
              backgroundColor:
                lightColor || darkColor
                  ? theme.palette.mode === "dark"
                    ? darkColor
                    : lightColor
                  : "default",
              borderTopLeftRadius: i === 0 ? "30px !important" : 0,
              borderTopRightRadius: i === 0 ? "30px !important" : 0,
              borderBottomLeftRadius:
                i === items.length - 1 ? "30px !important" : 0,
              borderBottomRightRadius:
                i === items.length - 1 ? "30px !important" : 0,
              p: 2,
            }}
            expanded={multiple ? undefined : expanded === encoded}
            onChange={() => setExpanded(expanded !== encoded ? encoded : false)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography textAlign="left" variant="h5">
                {item?.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RenderContent content={item?.description?.json} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};
