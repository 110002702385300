import { ThemeOptions } from "@mui/material";
import { themeConfig } from "./default";

export const lightTheme: ThemeOptions = {
  ...themeConfig,
  palette: {
    ...themeConfig.palette,
    mode: "light",
  },
};
