import {
  Button as MuiButton,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React, { FC } from "react";
import { colors } from "../../theme/colors";

interface ButtonProps {
  label?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  variant?: "primary" | "secondary" | "pink" | "transparent" | "outlined";
  onClick?: () => any;
  sx?: SxProps;
  type?: "submit" | "button";
  disabled?: boolean;
}

export const Button: FC<ButtonProps> = ({
  label,
  startIcon,
  endIcon,
  variant = "primary",
  onClick,
  sx,
  type = "button",
  disabled = false,
}) => {
  const theme = useTheme();

  const stylesMap = {
    primary: {
      color: theme.palette.mode === "dark" ? colors.white : colors.black,
      backgroundColor:
        theme.palette.mode === "dark" ? colors.black : colors.white,
      ":hover": {
        backgroundColor:
          theme.palette.mode === "dark" ? colors.gray : colors.lightGray,
      },
    },
    secondary: {
      color: colors.black,
      backgroundColor: colors.lightGray,
      ":hover": {
        backgroundColor: colors.gray,
      },
    },
    pink: {
      color: theme.palette.mode === "dark" ? colors.white : colors.black,
      backgroundColor:
        theme.palette.mode === "dark" ? colors.darkPink : colors.pink,
      ":hover": {
        backgroundColor:
          theme.palette.mode === "dark" ? colors.pink : colors.darkPink,
      },
    },
    transparent: {
      color: theme.palette.mode === "dark" ? colors.white : colors.black,
      backgroundColor: "transparent",
      ":hover": {
        backgroundColor: "hsla(0, 0%, 0%, 0.2)",
      },
    },
    outlined: {
      color: theme.palette.mode === "dark" ? colors.white : colors.black,
      backgroundColor: "transparent",
      ":hover": {
        backgroundColor: "hsla(0, 0%, 0%, 0.2)",
      },
      border: `1px solid ${
        theme.palette.mode === "dark" ? colors.white : colors.black
      }`,
      boxShadow: "none",
    },
  };

  return (
    <MuiButton
      onClick={onClick}
      sx={{
        borderRadius: 60,
        p: 2,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        ...stylesMap[variant],
        ...sx,
      }}
      disabled={disabled}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {startIcon && (
          <Box sx={{ display: "flex", alignItems: "center" }} mr={label && 2}>
            {startIcon}
          </Box>
        )}
        {label && <Typography fontWeight="bold">{label}</Typography>}
        {endIcon && (
          <Box sx={{ display: "flex", alignItems: "center" }} ml={label && 2}>
            {endIcon}
          </Box>
        )}
      </Box>
    </MuiButton>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  onClick: PropTypes.func,
};
