import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Grid, Paper, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { getHeaderOffset } from "../../const/sizes";
import { DataContext } from "../../context/DataContext";
import { Section } from "../../models/schema";
import { getButton } from "../../querries/buttons";
import { darkColors, lightColors } from "../../theme/colors";
import { Button } from "../utils/Button";
import RenderContent from "../utils/RenderContent";

const SectionSelector = ({ sections }: { sections?: Section[] }) => {
  const theme = useTheme();
  const { content } = useContext(DataContext);
  const buttons = content?.buttonsCollection?.items;

  return (
    <>
      {sections?.map((section, i) => (
        <Paper
          key={i}
          sx={{
            borderRadius: "30px",
            overflow: "hidden",
            p: 3,
            mb: 3,
            "& > :not(template) ~ :not(template)": {
              mt: { xs: 3, md: 0 },
              mr: { xs: 0, md: 3 },
            },
            backgroundColor:
              theme.palette.mode === "dark"
                ? darkColors[i % 4]
                : lightColors[i % 4],
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              lg={2}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">{section.name}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              lg={7}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <RenderContent content={section.description?.json} />
            </Grid>
            <Grid
              item
              xs={12}
              lg={3}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", lg: "flex-end" },
                alignItems: "center",
              }}
            >
              <AnchorLink
                offset={getHeaderOffset() + 20}
                href={`#${section?.id}`}
                style={{
                  textDecoration: "none",
                }}
              >
                <Button
                  label={getButton(buttons, "choose")}
                  endIcon={<ArrowForwardIcon />}
                ></Button>
              </AnchorLink>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </>
  );
};

export default SectionSelector;
